import {createApp} from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import routerGuard from "@/plugins/routerGuard";
import uuid from "@/plugins/uuid";
import appMeta from "@/plugins/appMeta";
import fontAwesome from "@/plugins/fontAwesome";
import httpClient from "@/plugins/httpClient";
import smsPayAssets from "@/sms-pay-assets";
import VueTelInput from 'vue-tel-input'
import moment from "moment";

import "@/assets/app.scss"
import "bootstrap/dist/js/bootstrap.min.js"

moment.locale("id");
createApp(App)
    .use(router)
    .use(store)
    .use(routerGuard, {loginName: 'Home'})
    .use(uuid)
    .use(appMeta)
    .use(fontAwesome)
    .use(httpClient, { baseUrl: 'https://api.smspay.co.id' })
    .use(smsPayAssets, { baseUrl: 'https://assets.smspay.co.id' })
    .use(VueTelInput)
    .mount('#app')