import {hasKeyObject} from "@/util/common";

export const ROLE_USER = "ROLE_USER";
export const ROLE_BUSINESS = "ROLE_BUSINESS";
export const ROLE_BUSINESS_MEMBER = "ROLE_BUSINESS_MEMBER";
export const ROLE_BUSINESS_ADMIN = "ROLE_BUSINESS_ADMIN";
export const ROLE_BUSINESS_SUPER_ADMIN = "ROLE_BUSINESS_SUPER_ADMIN";

export const roleHierarchy = {
    ROLE_BUSINESS: [ROLE_USER],
    ROLE_BUSINESS_MEMBER: [ROLE_BUSINESS],
    ROLE_BUSINESS_ADMIN: [ROLE_BUSINESS_MEMBER],
    ROLE_BUSINESS_SUPER_ADMIN: [ROLE_BUSINESS_ADMIN]
};

export function getRolesToDisplayInPartner() {
    return {
        ROLE_BUSINESS_MEMBER: {
            name: 'Anggota',
            accessDescription: 'Hanya Baca',
            deletable: true,
            selectable: true
        },
        ROLE_BUSINESS_ADMIN: {
            name: 'Admin',
            accessDescription: 'Akses Penuh',
            deletable: true,
            selectable: true
        },
        ROLE_BUSINESS_SUPER_ADMIN: {
            name: 'Super Admin',
            accessDescription: 'Akses Penuh',
            deletable: false,
            selectable: false
        }
    }
}

export const parseRole = function (role) {
    if (hasKeyObject(roleHierarchy, role)) {
        return roleHierarchy[role]
    }

    return [];
};

export const isEqualRole = (role, requiredRole) => {
    if (role === requiredRole) {
        return true
    }

    const deepRoles = parseRole(role)
    for (let x=0; x<deepRoles.length; x++) {
        const inDeep = isEqualRole(deepRoles[x], requiredRole)
        if (inDeep) {
            return true
        }
    }

    return false
};

export const inRoles = (hasRoles, requiredRole) => {
    for (let x=0; x<hasRoles.length; x++) {
        const isEqual = isEqualRole(hasRoles[x], requiredRole)
        if (isEqual) {
            return true
        }
    }

    return false
};

export function isRoleGranted(hasRoles, requiredRoles) {
    for (let x=0; x<requiredRoles.length; x++) {
        const exists = inRoles(hasRoles, requiredRoles[x])
        if (exists) {
            return true
        }
    }

    return false
}