export function ymdDateFormat(date, separator) {
    let month = '' + (date.getMonth() + 1),
        day = '' + date.getDate(),
        year = date.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join(separator || '');
}

export function getFirstAndLastDateOf(month, year) {
    return {
        first: new Date(year, month, 1),
        last: new Date(year, month + 1, 0)
    }
}

export function toCurrencyFormat(number) {
    return new Intl.NumberFormat("id-ID", {style: "currency", currency: "IDR"}).format(number);
}

export function numberSeparatorPoint(number) {
    return number.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.")
}

export function generatePaginationNumbers(currentPage, lastPage, onSides = 3) {
    // pages
    let pages = [];
    // Loop through
    for (let i = 1; i <= lastPage; i++) {
        // Define offset
        let offset = (i === 1 || lastPage) ? onSides + 1 : onSides;
        // If added
        if (i === 1 || (currentPage - offset <= i && currentPage + offset >= i) ||
            i === currentPage || i === lastPage) {
            pages.push(i);
        } else if (i === currentPage - (offset + 1) || i === currentPage + (offset + 1)) {
            pages.push('...');
        }
    }

    return pages;
}

export function toStrTrxStatus(status) {
    switch (status) {
        case '200':
            return 'Success';
        case '1200':
            return 'Pending';
        case '901':
            return 'Balance Transfer';
        case '911':
            return 'Canceled Balance Transfer';
        case '601':
            return 'Balance Retract';
        default:
            return 'Fail'
    }
}

export function isEmptyObject(obj) {
    return !!(obj &&
        Object.keys(obj).length === 0 &&
        Object.getPrototypeOf(obj) === Object.prototype);
}

export function hasKeyObject(obj, key) {
    return Object.prototype.hasOwnProperty.call(obj, key)
}

export function isPlainObject(obj) {
    if (typeof obj == 'object' && obj !== null) {
        if (typeof Object.getPrototypeOf == 'function') {
            const proto = Object.getPrototypeOf(obj);

            return proto === Object.prototype || proto === null;
        }

        return Object.prototype.toString.call(obj) === '[object Object]';
    }

    return false;
}